import React from 'react';

const PhysiologicalPhBadge = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="106.95"
    height="108.792"
    viewBox="0 0 106.95 108.792"
  >
    <defs>
      <clipPath>
        <rect width="106.95" height="108.792" transform="translate(0 0)" fill="currentColor" />
      </clipPath>
    </defs>
    <g transform="translate(0 0)" clipPath="url(#clip-path)">
      <path
        d="M1.3,34.284,2.35,32.528l1.267.436.035-.059a3.592,3.592,0,0,1-.427-1.637,3.17,3.17,0,0,1,.509-1.585A2.96,2.96,0,0,1,6.221,28.1a8.212,8.212,0,0,1,4.234,1.5,8.469,8.469,0,0,1,3.486,3.377,3.615,3.615,0,0,1-.232,3.588,4.342,4.342,0,0,1-.564.762,2.548,2.548,0,0,1-.636.477l4.006,2.4-1.408,2.348Zm9.062,2.239a1.531,1.531,0,0,0,.562-.268,2.015,2.015,0,0,0,.482-.581A1.52,1.52,0,0,0,11.345,34a7.1,7.1,0,0,0-2.4-2,6.019,6.019,0,0,0-2.27-.932,1.253,1.253,0,0,0-1.308.638,1.6,1.6,0,0,0,.36,2.039Z"
        fill="currentColor"
      />
      <path
        d="M16.369,22.186l-3.113,3.1,4.8,4.826L16,32.155,4.646,20.742,6.7,18.7l4.672,4.695,3.115-3.1L9.814,15.6l2.055-2.044L23.226,24.967l-2.054,2.044Z"
        fill="currentColor"
      />
      <path
        d="M21.149,7.455A16.2,16.2,0,0,1,22.683,6.44a19.132,19.132,0,0,1,1.757-.914,11.019,11.019,0,0,1,1.974-.685A4.6,4.6,0,0,1,28.4,4.784a4.188,4.188,0,0,1,1.865.931,6.294,6.294,0,0,1,1.59,2.272,6.934,6.934,0,0,1,.682,2.519,4.761,4.761,0,0,1-.321,2.042,4.33,4.33,0,0,1-1.1,1.561A6.3,6.3,0,0,1,29.5,15.178l-.272.123c-.127.058-.26.114-.4.171s-.283.108-.423.154a2.434,2.434,0,0,1-.3.088l2.3,5.044-2.638,1.2Zm5.869,5.894a2.645,2.645,0,0,0,.5-.143q.36-.125.486-.181a3.428,3.428,0,0,0,.822-.527,2.04,2.04,0,0,0,.547-.768,2.251,2.251,0,0,0,.131-1.069,4.514,4.514,0,0,0-.426-1.437,3.747,3.747,0,0,0-.712-1.091,2.144,2.144,0,0,0-.829-.545,2.037,2.037,0,0,0-.887-.089,3.011,3.011,0,0,0-.895.257,5,5,0,0,0-1.019.59Z"
        fill="currentColor"
      />
      <path
        d="M42.915,9.91l-4.329.751,1.162,6.708-2.855.494L34.145,2,37,1.506l1.131,6.526,4.329-.75L41.329.755,44.186.261l2.747,15.864-2.857.494Z"
        fill="currentColor"
      />
      <path
        d="M51.721,10.455,48.287,0l3.437.3,1.7,6.264L53.5,8.323l.116.01.406-1.765L56.719.73,59.813,1l-5.2,9.684-.518,5.98-2.888-.25Z"
        fill="currentColor"
      />
      <path
        d="M65.074,15.1a1.885,1.885,0,0,0-.02-1.327,5.693,5.693,0,0,0-.729-1.272q-.477-.643-1.057-1.344a8.443,8.443,0,0,1-1-1.519,5.66,5.66,0,0,1-.558-1.785A4.731,4.731,0,0,1,61.97,5.68,4.963,4.963,0,0,1,62.933,3.9a3.576,3.576,0,0,1,1.348-.958,3.938,3.938,0,0,1,1.637-.258,6.591,6.591,0,0,1,1.8.343,9.782,9.782,0,0,1,2,.907,5.927,5.927,0,0,1,1.4,1.117L69.463,7.2a4.1,4.1,0,0,0-.943-.846,5.808,5.808,0,0,0-1.51-.743,2.239,2.239,0,0,0-1.426-.085,1.273,1.273,0,0,0-.8.873,1.665,1.665,0,0,0,.057,1.219,6.074,6.074,0,0,0,.736,1.249q.477.643,1.053,1.355a8.658,8.658,0,0,1,.992,1.541,5.8,5.8,0,0,1,.551,1.807,4.725,4.725,0,0,1-.248,2.134,5.714,5.714,0,0,1-1,1.9,3.907,3.907,0,0,1-1.428,1.09,4.363,4.363,0,0,1-1.774.344,6.572,6.572,0,0,1-2.038-.362A9.948,9.948,0,0,1,59.4,17.622a6.352,6.352,0,0,1-1.364-1.1l1.714-2.171a6.746,6.746,0,0,0,1.025.922,5.31,5.31,0,0,0,1.551.806q2.182.723,2.748-.979"
        fill="currentColor"
      />
      <rect
        width="16.099"
        height="2.898"
        transform="translate(67.466 20.625) rotate(-61.888)"
        fill="currentColor"
      />
      <path
        d="M76.395,16.111a11.411,11.411,0,0,1,5.058-3.9,4.764,4.764,0,0,1,4.658,1.083,5.321,5.321,0,0,1,1.609,2.1,4.759,4.759,0,0,1,.293,2.423,8.1,8.1,0,0,1-.9,2.689,16.5,16.5,0,0,1-1.951,2.878,11.188,11.188,0,0,1-5.084,3.883,4.813,4.813,0,0,1-4.651-1.075,5.32,5.32,0,0,1-1.617-2.1,4.783,4.783,0,0,1-.284-2.415,7.9,7.9,0,0,1,.909-2.682,17.085,17.085,0,0,1,1.96-2.871m2.337,1.937a17.363,17.363,0,0,0-1.338,1.833,6.943,6.943,0,0,0-.8,1.684,3.269,3.269,0,0,0-.134,1.441,1.791,1.791,0,0,0,.668,1.122,2.139,2.139,0,0,0,2.408.232,10.5,10.5,0,0,0,3.287-2.921,17.277,17.277,0,0,0,1.31-1.8,7.561,7.561,0,0,0,.814-1.686A3.185,3.185,0,0,0,85.1,16.5a1.843,1.843,0,0,0-.688-1.153q-2.038-1.686-5.677,2.7"
        fill="currentColor"
      />
      <path
        d="M85.441,37.156l-5.077-6.888,12.961-9.551,1.719,2.332L84.232,31.018l3.357,4.556Z"
        fill="currentColor"
      />
      <path
        d="M92.889,33.844a11.4,11.4,0,0,1,6.3-1.083,4.768,4.768,0,0,1,3.61,3.138,5.323,5.323,0,0,1,.441,2.605,4.754,4.754,0,0,1-.876,2.278,8.114,8.114,0,0,1-2.053,1.955,16.619,16.619,0,0,1-3.072,1.63,11.2,11.2,0,0,1-6.311,1.051,4.808,4.808,0,0,1-3.605-3.126,5.323,5.323,0,0,1-.446-2.617,4.787,4.787,0,0,1,.881-2.266,7.9,7.9,0,0,1,2.059-1.946,17.12,17.12,0,0,1,3.076-1.619m1.158,2.806a17.5,17.5,0,0,0-2.041.993,7,7,0,0,0-1.492,1.113,3.262,3.262,0,0,0-.795,1.212,1.794,1.794,0,0,0,.066,1.3A2.137,2.137,0,0,0,91.8,42.6a10.474,10.474,0,0,0,4.272-1.043,16.9,16.9,0,0,0,2-.975,7.433,7.433,0,0,0,1.508-1.109,3.171,3.171,0,0,0,.816-1.219,1.84,1.84,0,0,0-.069-1.34q-1.01-2.445-6.282-.268"
        fill="currentColor"
      />
      <path
        d="M98.717,51.958l.617,4.93-7.074.885a5.037,5.037,0,0,1-1.4-1.828,7.582,7.582,0,0,1-.635-2.2,6.069,6.069,0,0,1,.174-2.5,4.66,4.66,0,0,1,1.261-2.047,7.558,7.558,0,0,1,2.43-1.51,14.658,14.658,0,0,1,3.7-.893,12.953,12.953,0,0,1,3.92.019,7.55,7.55,0,0,1,2.735,1.014,4.935,4.935,0,0,1,1.681,1.76,6.253,6.253,0,0,1,.738,2.284,9.61,9.61,0,0,1,.055,2.021,8.511,8.511,0,0,1-.258,1.55l-2.56-.281a5.842,5.842,0,0,0,.209-1.1,6.782,6.782,0,0,0-.048-1.489,3.273,3.273,0,0,0-.5-1.409,2.77,2.77,0,0,0-1.141-.969,5.357,5.357,0,0,0-1.854-.463,12.114,12.114,0,0,0-2.62.084,8.152,8.152,0,0,0-4.159,1.413,2.675,2.675,0,0,0-1.079,2.488,2.775,2.775,0,0,0,.249.861,1.971,1.971,0,0,0,.532.687l3.3-.414.03-2.668Z"
        fill="currentColor"
      />
      <rect
        width="2.898"
        height="16.099"
        transform="matrix(0.061, -0.998, 0.998, 0.061, 90.596, 62.059)"
        fill="currentColor"
      />
      <path
        d="M83.23,74.354a5.04,5.04,0,0,1,.014-.96,6.361,6.361,0,0,1,.172-.918,7.566,7.566,0,0,1,.7-1.753q.455-.82.951-1.569t.921-1.456a5.518,5.518,0,0,0,.609-1.4c.038-.148.069-.3.091-.439a1.922,1.922,0,0,0,.021-.435l2.489.66a4.582,4.582,0,0,1-.148,1.1,5.791,5.791,0,0,1-.622,1.466q-.424.708-.882,1.431c-.308.481-.608.982-.9,1.5a7.037,7.037,0,0,0-.681,1.687,6.528,6.528,0,0,0-.162.837,5.716,5.716,0,0,0-.041.918ZM98.6,65.338a11.4,11.4,0,0,1,5.667,2.953,4.764,4.764,0,0,1,.973,4.682,5.326,5.326,0,0,1-1.227,2.34,4.757,4.757,0,0,1-2.076,1.282,8.086,8.086,0,0,1-2.818.313,16.588,16.588,0,0,1-3.432-.563,11.194,11.194,0,0,1-5.657-2.985,4.8,4.8,0,0,1-.977-4.672,5.3,5.3,0,0,1,1.23-2.351,4.791,4.791,0,0,1,2.073-1.271,7.908,7.908,0,0,1,2.817-.3,17.032,17.032,0,0,1,3.427.574m-.776,2.936a17.046,17.046,0,0,0-2.226-.446,6.957,6.957,0,0,0-1.863-.019,3.268,3.268,0,0,0-1.365.485,1.781,1.781,0,0,0-.737,1.077,2.135,2.135,0,0,0,.8,2.281,10.488,10.488,0,0,0,4.033,1.758,16.878,16.878,0,0,0,2.179.434,7.52,7.52,0,0,0,1.872.032,3.187,3.187,0,0,0,1.388-.478,1.837,1.837,0,0,0,.757-1.107q.676-2.558-4.837-4.017"
        fill="currentColor"
      />
      <path
        d="M97.863,89.145l-1.381,2.363-9.017-5.263q-2.7-1.578-3.27-3.387a4.536,4.536,0,0,1,.568-3.756A4.96,4.96,0,0,1,87.9,76.445a6.078,6.078,0,0,1,4.226,1.042l9.355,5.46-1.461,2.5-8.918-5.2a4.949,4.949,0,0,0-2.529-.891,1.871,1.871,0,0,0-1.492,1.073,1.733,1.733,0,0,0-.127,1.79,5.214,5.214,0,0,0,1.991,1.722Z"
        fill="currentColor"
      />
      <path
        d="M93.419,95.35l-5.589,5.795-1.92-1.852,3.576-3.709L86.64,92.838,83.4,96.2l-1.92-1.853,3.241-3.36-2.98-2.874L78.1,91.886l-1.921-1.851,5.652-5.861Z"
        fill="currentColor"
      />
      <path
        d="M83.766,58.078a34.7,34.7,0,1,1-34.7-34.7,34.7,34.7,0,0,1,34.7,34.7"
        fill="currentColor"
      />
      <path
        d="M49.066,108.792A49.1,49.1,0,0,1,2,45.819a1.5,1.5,0,0,1,2.877.85A46.077,46.077,0,0,0,75.222,97.65a1.5,1.5,0,0,1,1.705,2.469,48.78,48.78,0,0,1-27.861,8.673"
        fill="currentColor"
      />
      <path
        d="M40.8,41.1c1.34-1.26,2.632-2.527,3.981-3.73,1.116-1,2.283-1.938,3.458-2.864a2.7,2.7,0,0,1,3.386-.034,57.286,57.286,0,0,1,14.03,15.1,22.782,22.782,0,0,1,3.158,7.874,18.149,18.149,0,0,1-1.4,10.637A.836.836,0,0,0,67.5,69a8.6,8.6,0,0,1,1.486,3.252,5.888,5.888,0,0,1-1.332,4.861,6.189,6.189,0,0,1-9.1.713.475.475,0,0,0-.616-.093,19.057,19.057,0,0,1-25.575-9.931A18.035,18.035,0,0,1,32.9,52.08a.575.575,0,0,0-.144-.817,5.9,5.9,0,0,1-1.272-7.008,26.683,26.683,0,0,1,3.942-5.466c.177-.2.359-.4.542-.589a1.391,1.391,0,0,1,2.343,0c.771.862,1.511,1.751,2.264,2.628.077.09.151.181.229.275m1.539,2.26c.13.254.22.439.318.62a5.726,5.726,0,0,1-.2,6.155,6.264,6.264,0,0,1-6.623,2.776c-.232-.048-.367-.038-.488.209a15.982,15.982,0,0,0-1.61,10A16.48,16.48,0,0,0,55.244,75.937c.539-.183,1.061-.413,1.534-.6-.117-.685-.262-1.278-.313-1.879a6.3,6.3,0,0,1,1.113-3.729A29.567,29.567,0,0,1,61.63,64.5a1.38,1.38,0,0,1,2.285.02c.471.5.929,1.019,1.4,1.541.043-.053.07-.073.079-.1a15.434,15.434,0,0,0,.784-8.046,21.143,21.143,0,0,0-3.117-7.458A56.21,56.21,0,0,0,50.24,36.758a.405.405,0,0,0-.61.03c-1.075.91-2.189,1.776-3.231,2.721-1.382,1.253-2.71,2.565-4.061,3.852M62.767,67.213a21.981,21.981,0,0,0-3.179,4.413,3.742,3.742,0,0,0-.449,1.469,3.66,3.66,0,0,0,4.382,3.636c2.213-.486,3.428-3.025,2.633-4.685a22.713,22.713,0,0,0-3.387-4.833M37.137,40.856a18.135,18.135,0,0,0-3.46,5.128,2.919,2.919,0,0,0-.071,1.558A3.622,3.622,0,0,0,40,48.97a3.06,3.06,0,0,0,.3-3.724c-.638-1.046-1.35-2.049-2.057-3.052-.32-.455-.708-.861-1.106-1.338"
        fill="#fff"
      />
      <path
        d="M43.457,63.481c0,1.008,0,1.986,0,2.964,0,.948-.519,1.558-1.325,1.565a1.365,1.365,0,0,1-1.346-1.543c0-3.188,0-6.377,0-9.565a1.4,1.4,0,0,1,.685-1.35,1.322,1.322,0,0,1,1.5.11.537.537,0,0,0,.428.007,4.185,4.185,0,1,1,.513,7.93l-.453-.118m1.5-2.416a1.511,1.511,0,1,0-1.494-1.549,1.519,1.519,0,0,0,1.494,1.549"
        fill="#fff"
      />
      <path
        d="M53.423,55.64a4.148,4.148,0,0,1,4.053.6,3.993,3.993,0,0,1,1.635,3.211c.026.948.011,1.9.005,2.846a1.338,1.338,0,1,1-2.668-.009c-.005-.875,0-1.749,0-2.624a1.515,1.515,0,1,0-3.021.006c0,.875,0,1.75,0,2.624a1.339,1.339,0,1,1-2.669,0q0-4.869,0-9.739a1.34,1.34,0,1,1,2.669.013c0,1.007,0,2.015,0,3.072"
        fill="#fff"
      />
    </g>
  </svg>
);

export default PhysiologicalPhBadge;
